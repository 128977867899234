<template>
<div>
    <b-overlay :show="loading" rounded="sm">
             <b-card
                img-top
                :img-src="thumbnail"
            >
            <b-icon icon="bounding-box" ></b-icon> {{ $tc('Images.ImageCard.added_annotations', annotations.length, {amount: annotations.length}) }} <br/>
            <b-icon icon="tag" font-scale="1"></b-icon>{{ $tc('Images.ImageCard.added_activities', image.activities.length, {amount: image.activities.length}) }}
            <template #footer>
                <b-button-group>
                    <b-button variant="primary"  v-if="user.is_superuser" :class="{disabled: !image.public}" @click="annotate()" :title="$t('annotate')"><b-icon icon="bounding-box" ></b-icon> {{$t('annotate')}}</b-button>
                    <b-button variant="secondary" v-if="image.owner == user.url || user.is_superuser" :to="meta" :title="$t('Images.ImageCard.edit_metadata')"><b-icon icon="pencil-square"></b-icon></b-button>
                </b-button-group>
                <b-button class="float-right" v-on:click="flag" v-if="image.owner != user.url" variant="danger" :title="$t('Images.ImageCard.flag')"><b-icon icon="flag"></b-icon></b-button>
            </template>


            </b-card>
    </b-overlay>
</div>
            
</template>

<script>
import { apiService } from '../../services'

export default {
  name: 'ImageCard',
  props: [
      'image',
      'user',
  ],
  data(){
      return {
          annotations: [],
          report_modal: false,
          loading: true,
      }
  },
  methods: {
      flag(){
          this.$emit('flag', this.image) 
      },
      annotate(){
          if(this.image.public){
            this.$router.push({name: 'ImageAnnotation', params: {id: this.image.id}})
          } else {
            this.$bvModal.msgBoxOk(this.$t('Images.ImageCard.message_box.content'), {
              title: this.$t('Images.ImageCard.message_box.title'),
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'primary',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
          } 
      }
  },
  computed: {
      thumbnail() {
         return `${process.env.VUE_APP_DJANGO_BASE_URL}image/thumbnail/${this.image.id}`
      },
    meta() {
          return `/image/meta/${this.image.id}`
      }
  },
 mounted() {
    apiService.get({url: `annotations/?image=${this.image.id}&owner=false`})
                    .then(response => {
                    for (let t in response.results){
                        this.annotations.push(response.results[t].annotation)
                    }
                    this.loading = false
                })
                .catch(error => {
                    this.$emit('toast', this.$t('Images.ImageCard.error_toast.title'), this.$t('Images.ImageCard.error_toast.title', {id: this.image.id, error}), 'danger')
                })  
        },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
