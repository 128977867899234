<template>
<div>
        <b-row>
            <b-col>
                <h1>{{ $t('Surveys.title') }}</h1>
                <p class="lead">
                    {{ $t('Surveys.lead')}}
                </p>
            </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-overlay :show="loading" rounded="sm">
              <b-card-group v-if="surveys.length > 0" deck>
                <SurveyElement class="element" v-for="survey in surveys" v-bind:key="survey.id" :survey="survey" v-on="$listeners" :settings="settings" :user="user" />
              </b-card-group>
              <div class="text-muted" v-else>
                <div class="d-flex justify-content-center"><b-icon font-scale="5" icon="signpost-split"></b-icon></div>
                  <div class="d-flex justify-content-center">
                    <h3>{{ $t('Surveys.all_done') }}</h3>
                    </div>
              </div>
            </b-overlay>
          </b-col>
        </b-row>
</div> 
</template>

<script>
import { apiService } from '../services'
import SurveyElement from './surveys/SurveyElement.vue'
export default {
  name: 'SurveyList',
  components: {
      SurveyElement
  },
    props: {
      'user': Object,
      'settings': Function
  },
  computed: {
  },
  data() {
      return {
          data_loading: true,
          surveys: null,
      }
  },
methods: {
    init() {
    this.data_loading = true

    apiService.get({url: `surveys/`, method: 'GET'})
      .then(response => {   
        if(response){
            this.surveys = response.results
            this.data_loading = false
        }
      })
    },
  },
  mounted() {
    this.init()
  },
};
</script>

<style scoped>
.badge {
    margin-right: 3px;
}
.element {
  margin-bottom: 10px;
}
</style>