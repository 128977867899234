<template>

    <b-container >
        <b-row>
            <b-col>
                <h1>{{ $t('Images.title') }}</h1>
                <p class="lead">
                    {{ $t('Images.leader')}}
                </p>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card bg-variant="light">
                    <b-form-group
                    label-cols-lg="3"
                    :label="$t('Images.filter_images')"
                    label-size="md"
                    label-class="font-weight-bold pt-0"
                    class="mb-0"
                    >
                    <b-form-group
                        :label="$t('Images.uploaded_by')"
                        label-for="owners"
                        label-cols-sm="3"
                        label-align-sm="right"
                    >
                        <b-form-select v-model="filter.owner" id="owners" :options="owners"></b-form-select>
                    </b-form-group>
                    <b-form-group
                        label-cols-sm="3"
                        label-align-sm="right" 
                        >
                        <b-form-checkbox
                        id="published"
                        v-model="filter.unpublished"
                        name="published">
                        {{$t('Images.unpublished_only')}}</b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                        label-cols-sm="3"
                        label-align-sm="right" 
                        >
                        <b-form-checkbox
                        id="activities"
                        v-model="filter.activities"
                        name="activities">
                        {{$t('Images.missing_activities')}}</b-form-checkbox>
                    </b-form-group>
                                        <b-form-group
                        label-cols-sm="3"
                        label-align-sm="right" 
                        >
                        <b-form-checkbox
                        id="annotations"
                        v-model="filter.annotations"
                        name="annotations">
                        {{$t('Images.missing_annotations')}}</b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                        label-cols-sm="3"
                        label-align-sm="right"
                    > <b>{{ $tc('Images.found_images', totalImages, {amount: totalImages})}}</b>
                    </b-form-group>
                    </b-form-group>
                    <br />
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <br />
                <b-button block @click="goto('image/upload')" variant="secondary"><b-icon icon="upload"></b-icon> Upload New Image</b-button>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col>
                <b-overlay :show="loading" rounded="sm">
                <b-card-group v-if="images.length > 0" columns>
                <ImageCard v-on="$listeners" @flag="flag" v-for="image in images" v-bind:key="image.id" :image="image" :user="user" />
                </b-card-group>
                <div class="text-muted" v-else>
                    <div class="d-flex justify-content-center"><b-icon font-scale="5" icon="signpost-split"></b-icon></div>
                    <div class="d-flex justify-content-center">
                        <h3>{{ $t('Images.none') }}</h3>
                        </div>
                </div>
                <hr />
                
                <div class="mx-auto">
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="totalImages"
                    :per-page="perPage"
                    align="center"
                    ></b-pagination>
                </div>
                </b-overlay>
            </b-col>
        </b-row>

        <br />
        <br />

        <b-modal ok-variant="danger" @ok="commit_flag" :ok-title="$t('Images.report.action')" id="flag-modal" centered :title="$t('Images.report.title')" ref="flag-modal">
            <img :src="thumbnail" size="lg">
            <b-form>
            <b-form-group
                id="input-group-1"
                :label="$t('Images.report.question')"
                label-for="input-1"
            >
                <b-form-textarea
                id="input-1"
                v-model="flagging.message"
                :placeholder="$t('Images.report.response')"
                rows="3"
                max-rows="6"
                ></b-form-textarea>
            </b-form-group>
            </b-form>
        </b-modal>

    </b-container>

</template>

<script>
import ImageCard from '../components/images/ImageCard.vue'
import { apiService } from '../services'

export default {
  name: 'Images',
  props: {
      'user' : Object,
      'settings': Function
  },
  components: {
      ImageCard
  },
  methods: {
    goto(destination) {
          this.$router.push(destination)
      },
      flag(image){
          this.flagging.image = image
          this.$refs['flag-modal'].show()
      },
      commit_flag(){
          const flag = {
            user: this.user.url,
            image: this.flagging.image.url,
            message: this.flagging.message,
            resolved: false 
          }
          const image = this.flagging.image
          image.public = false
          delete image['image'] 
          delete image['thumbnail'] 

          apiService.get({url: 'flags/', method:"POST", body:flag})
            .then(() => {
                this.$emit('toast', 'Report Successfull', 'Your report was saved successfully.', 'success')

                apiService.get({url: image.url, method:"PATCH", body: image})
                    .then(() => {
                        this.$emit('toast', 'Temporary Takedown Successfull', 'The flagged image has been taken down temporarily pending review of your report.', 'success')
                    })
            })
            .catch(error => {
                this.$emit('toast', 'Report Failed', `Your report failed. Please try again. ${error}`, 'danger')
            })
      },
      get_images(){
          this.loading = true
          this.image = []
          apiService.get({url: this.url})
            .then(response => {
                this.images = response.results
                this.totalImages = response.count
                this.loading = false
            })
      }
  },
  data() {
      return {
          images: [],
          flagging: {
              image: Object,
              message: ""
          },
          totalImages: 0,
          currentPage: 1,
          perPage: 10, // Defined in backe-end. Optionally the backend accepts the GET-Parameter `page_size`
          owners: ['Everyone', 'Me', 'Others'],
          filter: {
              owner: 'Everyone',
              activities: false,
              annotations: false,
              unpublished: false,
          },
          loading: true,
      }
  },
  computed: {
     thumbnail() {
         return `${process.env.VUE_APP_DJANGO_BASE_URL}image/thumbnail/${this.flagging.image.id}`
      },
      url(){
            let url = `images/?page=${this.currentPage}`

            if(this.filter.owner == "Me")
                url = url + "&owner=me"

            if(this.filter.owner == "Others")
                url = url + "&owner=others"
            
            if(this.filter.activities)
                url = url + "&activities=true"
            
            if(this.filter.annotations)
                url = url + "&annotations=true"
            
            if(this.filter.unpublished)
                url = url + "&unpublished=true"    
            
            return url
      }
  },
  watch: {
      currentPage(){
          this.get_images()
      },
      filter: {
          handler(){
            this.currentPage = 1    // Going back to page 1
            this.get_images()
      },
      deep: true},
  },
  mounted() {
      this.get_images()
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
