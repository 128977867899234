<template>

    <b-container>
        <SurveySubmit v-on="$listeners" :settings="settings" :user="user" v-if="activeComponent == 'SurveySubmit'" />
        <SurveyList v-on="$listeners" :settings="settings" :user="user" v-if="activeComponent == 'SurveyList'" />
    </b-container>

</template>

<script>
import SurveySubmit from '../components/SurveySubmit'
import SurveyList from '../components/SurveyList'


export default {
  name: 'Survey',
  props: {
      'user' : Object,
      'settings': Function
  },
  components: {
      SurveyList,
      SurveySubmit
  },
  methods: {
  },
  data() {
      return {
          
      }
  },
  computed: {
      activeComponent() {
          if(this.$route.params.id) {
              return 'SurveySubmit'
          } else {
              return 'SurveyList'
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
