<template>
<div>
  <b-container>
      <b-row>
          <b-col>
            <h1>{{ $t('XPLog.title') }}</h1>
            
            <p class="lead">
               {{ $t('XPLog.lead') }}
            </p>
            
            <b-button block variant="light"><b-icon icon="question-circle"></b-icon> {{ $t('more_information') }}</b-button>
            <hr />
          </b-col>
      </b-row>
      <b-row>
          <b-col>
            <b-card-group columns>
                <XPCard :xp="metrics.xp" :threshold="next_level.threshold" />
                <VPCard :vp="metrics.vp" />
                <TimeCard :xp="metrics.xp" :threshold="next_level.threshold" :duration="metrics.duration" />
            </b-card-group>
          </b-col>
      </b-row>
      <b-row>
          <b-col>
            <h2>{{ $t('XPLog.logged_actions') }}</h2>
            <b-table striped hover :items="XPLog" :fields="fields">
                <template #cell(created)="data">
                {{format_datetime(data.item.created)}}
            </template>
            </b-table>
          </b-col>
      </b-row>
  </b-container>
</div>
</template>
<script>
import XPCard from '../components/dashboard/XPCard.vue'
import VPCard from '../components/dashboard/VPCard.vue'
import TimeCard from '../components/dashboard/TimeCard.vue'

import { apiService } from '../services'
import moment from 'moment';


export default {
  name: 'XPLog',
  components: {
      XPCard,
      VPCard,
      TimeCard
  },
  data() {
      return {
        fields: [{
                key:'created',
                sortable: true
            }, 
            {
                key:'xp',
                sortable: true
            },
            {
                key:'vp',
                sortable: true
            }, 'activity', 'key'],
        XPLog: null,
        metrics: 0,
        next_level: {
            threshold: 0
        },
      }
  },
  computed: {
  },
  methods: {
      format_datetime(dt){
        moment.locale()
        return moment(dt).format('l LT')  
      }
  },
  mounted() {
      apiService.get({url: 'xplogs/'})
        .then(response => {
            if(response.count > 0){
                this.XPLog = response.results
            }
        })

      apiService.get({url: 'metrics'})
        .then(response => {
            this.metrics = response 
        })        
        .then(() => apiService.get({url: `level/`, method: 'GET'})
            .then(response => {   
                this.levels = response.results     
                })
                .then(() => {
                        let next_level = this.levels.filter(item => item.threshold >= this.metrics.xp)
                        if(next_level)
                            this.next_level = next_level[0]
                }) 
        )
  },
  watch: {
  },
  props: {
      'user': Object,
      'settings': Function,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
