<template>
<div>
  <b-container>
    <b-card class="card" bg-variant="light">
    <b-form-group
      label-cols-lg="3"
      :label="$t('Profile.user_settings')"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-6"
        :label="$t('Profile.username')"
        label-for="input-6"
      >
        <b-form-input
          id="input-6"
          v-model="user.username"
          type="text"
          :placeholder="$t('Profile.your_email')"
          disabled
          required
          ></b-form-input>
      </b-form-group>


      <b-form-group id="input-group-2" :label="$t('Profile.first_name')" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="user.first_name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" :label="$t('Profile.last_name')" label-for="input-3">
        <b-form-input
          id="input-3"
          v-model="user.last_name"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">{{$t('Profile.submit')}}</b-button>
    </b-form>
        </b-form-group>
    </b-card>


    <b-card class="card" bg-variant="light">
    <b-form-group
      label-cols-lg="3"
      :label="$t('Profile.profile_settings')"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
    <b-form @submit="onSubmit">
                    <b-form-group
                        id="vp-select-group"
                        :label="$t('Profile.vp_question')"
                        label-for="vp-select-1"
                    >
                    
                    <b-form-select id="vp-select-1" v-model="user.profile.needs_vp" :options="vp_options"></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="vp-input-group"
                        :label="$t('Profile.registration_number')"
                        label-for="vp-input-1"
                        :description="$t('Profile.registration_number_description')"
                    >
                        <b-form-input
                        id="vp-input-1"
                        v-model="user.profile.matrikel"
                        type="text"
                        placeholder="1231232"
                        ></b-form-input>
                    </b-form-group>

      <b-button type="submit" variant="primary">{{$t('Profile.submit')}}</b-button>
    </b-form>
        </b-form-group>
    </b-card>

  </b-container>
</div>
</template>
<script>
import { apiService } from '../services'

export default {
  name: 'Profile',
  components: {
  },
  data() {
      return {
            vp_options: [
              { value: true, text: 'Yes'},
              { value: false, text: 'No'}
            ]
      }
  },
  computed: {
  },
  methods: {
      onSubmit(event){
        event.preventDefault()
        
        apiService.get({url: this.user.profile.url, body:this.user.profile, method: "PATCH"})
            .then(() =>{
                apiService.get({url: this.user.url, body:this.user, method: "PATCH"})
                    .then(() =>{
                        this.$store.dispatch('authentication/updateUser')
                        this.$emit('toast', this.$t('Profile.success_toast.title'), this.$t('Profile.success_toast.message'), 'success')
                    })
                    .catch(error => {
                        this.$emit('toast', this.$t('Profile.error_toast.title'), this.$t('Profile.error_toast.message', { error }), 'danger')
                    })
            })
            .catch(error => {
              this.$emit('toast', this.$t('Profile.error_toast.title'), this.$t('Profile.error_toast.message', { error }), 'danger')
            })
      }
  },
  mounted() {
  },
  watch: {
  },
  props: {
      'user': Object,
      'settings': Function,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
    margin-right: 5px;
}
.card {
    margin-bottom: 15px;
}
</style>
