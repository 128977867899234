<template>
    <div>
        <b-skeleton-wrapper :loading="data_loading">

              <template #loading>
            <b-skeleton animation="wave" width="25%"></b-skeleton>
            <hr />
            <b-skeleton animation="wave" width="30%"></b-skeleton>
            <b-skeleton animation="wave" width="65%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
            <b-skeleton animation="wave" width="24%"></b-skeleton>

              </template>
              <SurveyBadges v-model="survey" />
            <h1>{{ getTranslated(this.survey.translations, 'title')  }}</h1>
            <p class="lead">{{ getTranslated(this.survey.translations, 'description') }}</p>
      <hr />
      <b-card 
        :header="getTranslated(this.survey.translations, 'title')"
        header-tag="header"
        >
        <b-card-text>
            <survey v-if="model" :survey="model"></survey>
         </b-card-text>

      </b-card>
           

        </b-skeleton-wrapper>
  </div>
</template>

<script>
import * as SurveyVue from "survey-vue";
var Survey = SurveyVue.Survey;
Survey.cssType = "bootstrap";
import { apiService, translationService } from '../services'

import moment from 'moment';

import * as widgets from "surveyjs-widgets";
import SurveyBadges from './surveys/SurveyBadges.vue'

// widgets.icheck(SurveyVue);
widgets.select2(SurveyVue);
widgets.inputmask(SurveyVue);
widgets.jquerybarrating(SurveyVue);
widgets.jqueryuidatepicker(SurveyVue);
widgets.nouislider(SurveyVue);
widgets.select2tagbox(SurveyVue);
widgets.sortablejs(SurveyVue);
widgets.ckeditor(SurveyVue);
widgets.autocomplete(SurveyVue);
widgets.bootstrapslider(SurveyVue);

SurveyVue.Serializer.addProperty("question", "tag:number");

export default {
  name: 'SurveySubmit',
  components: {
    SurveyBadges,
  },
    props: {
      'user': Object,
      'settings': Function
  },
  computed: {
      id(){
        return this.$route.params.id
      },
  },
  data() {
      return {
          data_loading: true,
          survey: null,
          model: null,
          response: {
              survey: null,
              response: {},
              completed: false,
              owner: null,
          },
          timer: null,
      }
  },
methods: {
    getTranslated: translationService.getTranslated,
    reset_timer() {
        if(this.timer)
          clearTimeout(this.timer);
        
        this.timer = setTimeout(this.save_data, 30000)  // autosave every 30 seconds
    },
    save_data(){       
        if(this.response){
            let method = "POST"
                if(this.response.response){
                    method = "PATCH"
                }
            apiService.get({url: `${this.response.url}`, method: method, body: this.response})
                .then(() => {
                  this.$emit('toast', 'Autosaved', `Current progress saved automatically.`, 'success')
                })
        }

        this.reset_timer()


    },
    init() {
    this.data_loading = true

    this.response.owner = this.user.url

    apiService.get({url: `surveys/${this.id}/`, method: 'GET'})
      .then(response => {   
        if(response){
            this.survey = response
            this.response.survey = this.survey.url
            this.model = new SurveyVue.Model(this.getTranslated(this.survey.translations, 'body'));
            this.model.title = "" // Removing the title from the rendered SurveyJS-Form as we add it through our own Vue Layout
            let self = this;
            this.model
                .onValueChanged
                .add(function (sender) {
                    self.response.response = sender.data
                })
            this.model
                .onComplete
                .add(function (sender) {
                    self.response.response = sender.data
                    self.response.completed = true
                    self.save_data()
                })
        }
      })
      .then(() => {

        // There are three option: 1) We want to load a specific instance of response, set by GET-Variable `response`, 2) we specifically want to create a new reponse, 3) we would like to load the latest response
        // TODO: Code hier aufräumen (it's quite a mess!)
        const response_id = this.$route.query.response

        if(!response_id){
          apiService.get({url: `surveyresponses/?survey=${this.id}&completed=False`, method: 'GET'})
          .then(response => {
              if(response.count == 1){
                  this.response = response.results[0]
                  this.model.data = response.results[0].response
                  this.$emit('toast', 'Loaded Unsubmitted Data', `Loaded data last modified ${moment(this.response.modified).format('MMMM Do YYYY, h:mm:ss a')} `, 'success')
              } else {
                  apiService.get({url: `surveyresponses/`, method: 'POST', body:this.response})
                  .then((response) => {
                      this.response = response
                  })
              }
            this.reset_timer()
            this.data_loading = false  
          })
        }

        if(response_id == -1){
          apiService.get({url: `surveyresponses/`, method: 'POST', body:this.response})
                  .then((response) => {
                      this.response = response
                      this.reset_timer()
                      this.data_loading = false 
                  })
        } else {
           apiService.get({url: `surveyresponses/${response_id}/`, method: 'GET'})
                  .then((response) => {
                      this.response = response
                      this.model.data = response.response
                      this.reset_timer()
                      this.data_loading = false 
                  })         
        }


      })
    },
  },
  mounted() {
    this.init()
  },
  beforeRouteLeave () {
    // When leaving the survey we cancel the timer and null the variable.
    if(this.timer)
      clearTimeout(this.timer)
    this.timer = null
   },
};
</script>

<style scoped>

</style>