
<template>
<b-card
                    :title="$t('Dashboard.VPCard.title')"
                    >
                    <b-card-text class="text-center">
                        <h1 class="display-1">{{vp}} <small class="text-muted"><b-icon icon="credit-card2-back" font-scale=".5"></b-icon></small></h1>
                        <small class="text-muted"><b-icon icon="arrow-up"></b-icon>{{ $t('Dashboard.VPCard.hint') }}</small>

                    </b-card-text>

                </b-card>
</template>

<script>

export default {
  name: 'VPCard',
  props: [
      'vp',
      'threshold'
  ],
  computed: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
